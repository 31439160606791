<template>
  <div class="row">
    <div class="col-12">
      <c-table
        ref="grid"
        title="모든 작업계획별 소요자재"
        :columns="grid.columns"
        :data="grid.data"
        :isTitle="true"
        :editable="false"
        :hideBottom="true"
        :isExcelDown="false"
        :gridHeightAuto="true"
        :filtering="false"
        :isFullScreen="false"
        :columnSetting="false"
      >
        <template v-slot:customArea="{ props }">
          <template>
            <q-btn
              flat
              size="12px"
              color="orange"
              icon="chevron_right"
              @click="rowClickDetail(props.row, props.pageIndex)" />
          </template>
        </template>
      </c-table>
    </div>
    <q-dialog v-model="dialogDetail" :position="positionDetail">
      <q-card style="width: 100%">
        <q-linear-progress :value="1" color="pink" />
        <q-card-section class="row">
          <div class="col-12 text-weight-bold-dailog" v-text="selectedRow.vendorName"></div>
          <div class="col-4"><div class="mini-dailog-title">작업명</div></div>
          <div class="col-8" v-text="selectedRow.workPlanWorkName"></div>
          <div class="col-4"><div class="mini-dailog-title">작업제어구분</div></div>
          <div class="col-8" v-text="selectedRow.workOprTypeName"></div>
          <div class="col-4"><div class="mini-dailog-title">작업기간</div></div>
          <div class="col-8" v-text="selectedRow.workDtsStr"></div>
          <div class="col-2"><div class="mini-dailog-title">자재No</div></div>
          <div class="col-4" v-text="selectedRow.materialNo"></div>
          <div class="col-2"><div class="mini-dailog-title">용도</div></div>
          <div class="col-4" v-text="selectedRow.materialDesc"></div>
          <div class="col-2"><div class="mini-dailog-title">단가</div></div>
          <div class="col-4" v-text="selectedRow.unitPrice"></div>
          <div class="col-2"><div class="mini-dailog-title">수량</div></div>
          <div class="col-4" v-text="selectedRow.unitEa"></div>
          <div class="col-2"><div class="mini-dailog-title">소계</div></div>
          <div class="col-4" v-text="selectedRow.priceCost"></div>
          <div class="col-12 mini-dailog-btn-impr">
            <q-btn-group outline>
              <c-btn
                :showLoading="false"
                label="닫기"
                icon="close"
                @btnClicked="dialogClose"
              />
            </q-btn-group>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <div class="col-12">
      <c-table
        ref="grid2"
        title="모든 작업계획별 서비스(외주)"
        :columns="grid2.columns"
        :data="grid2.data"
        :isTitle="true"
        :editable="false"
        :hideBottom="true"
        :isExcelDown="false"
        :gridHeightAuto="true"
        :filtering="false"
        :isFullScreen="false"
        :columnSetting="false"
      >
        <template v-slot:customArea="{ props }">
          <template>
            <q-btn
              flat
              size="12px"
              color="orange"
              icon="chevron_right"
              @click="rowClickDetail2(props.row, props.pageIndex)" />
          </template>
        </template>
      </c-table>
    </div>
    <q-dialog v-model="dialogDetail2" :position="positionDetail2">
      <q-card style="width: 100%">
        <q-linear-progress :value="1" color="pink" />
        <q-card-section class="row">
          <div class="col-12 text-weight-bold-dailog" v-text="selectedRow2.vendorName"></div>
          <div class="col-4"><div class="mini-dailog-title">작업명</div></div>
          <div class="col-8" v-text="selectedRow2.workPlanWorkName"></div>
          <div class="col-4"><div class="mini-dailog-title">작업제어구분</div></div>
          <div class="col-8" v-text="selectedRow2.workOprTypeName"></div>
          <div class="col-4"><div class="mini-dailog-title">작업기간</div></div>
          <div class="col-8" v-text="selectedRow2.workDtsStr"></div>
          <div class="col-4"><div class="mini-dailog-title">원가요소</div></div>
          <div class="col-8">
            <c-select
              :editable="false"
              codeGroupCd="WO_COST_FACOTR_CD"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="costFactorCd"
              label=""
              v-model="selectedRow2.costFactorCd">
            </c-select>
          </div>
          <div class="col-2"><div class="mini-dailog-title">작업인원</div></div>
          <div class="col-4" v-text="selectedRow2.manCnt"></div>
          <div class="col-2"><div class="mini-dailog-title">인력단가</div></div>
          <div class="col-4" v-text="selectedRow2.priceCost"></div>
          <div class="col-2"><div class="mini-dailog-title">인건비</div></div>
          <div class="col-4" v-text="selectedRow2.manCost"></div>
          <div class="col-2"><div class="mini-dailog-title">자재비</div></div>
          <div class="col-4" v-text="selectedRow2.materialCost"></div>
          <div class="col-2"><div class="mini-dailog-title">합계</div></div>
          <div class="col-4" v-text="selectedRow2.sumCost"></div>
          <div class="col-12 mini-dailog-btn-impr">
            <q-btn-group outline>
              <c-btn
                :showLoading="false"
                label="닫기"
                icon="close"
                @btnClicked="dialogClose2"
              />
            </q-btn-group>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  props: {
    param: {
      type: Object,
      default: () => ({
        workPlanId: '',
        workPlanWorkId: '',
        equips: [],
        worksData: {},
      }),
    },
    click: {
      type: String,
      default: '',
    },
    contentHeight: null,
  },
  data() {
    return {
      dialogDetail: false,
      positionDetail: 'bottom',
      selectedRow: {},
      selectedRowIdx: -1,
      dialogDetail2: false,
      positionDetail2: 'bottom',
      selectedRow2: {},
      selectedRowIdx2: -1,
      editable: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '항목번호',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'workPlanWorkName',
            field: 'workPlanWorkName',
            label: '작업명',
            align: 'left',
            sortable: false,
          },
          // {
          //   name: 'workOprTypeName',
          //   field: 'workOprTypeName',
          //   label: '작업제어구분',
          //   align: 'center',
          //   style: 'width:120px',
          //   sortable: false,
          // },
          // {
          //   name: 'workDtsStr',
          //   field: 'workDtsStr',
          //   label: '작업기간',
          //   align: 'center',
          //   style: 'width:180px',
          //   sortable: false,
          // },
          // {
          //   name: 'materialNo',
          //   field: 'materialNo',
          //   label: '자재번호',
          //   align: 'center',
          //   style: 'width: 120px',
          //   sortable: false,
          // },
          {
            name: 'materialName',
            field: 'materialName',
            label: '자재',
            align: 'left',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'customDetail',
            field: 'customDetail',
            label: '',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false
          },
          // {
          //   name: 'materialDesc',
          //   field: 'materialDesc',
          //   label: '용도',
          //   align: 'left',
          //   sortable: false,
          // },
          // {
          //   name: 'unitPrice',
          //   field: 'unitPrice',
          //   label: '단가',
          //   align: 'right',
          //   style: 'width: 150px',
          //   sortable: false,
          // },
          // {
          //   name: 'unitEa',
          //   field: 'unitEa',
          //   label: '수량',
          //   align: 'right',
          //   style: 'width: 150px',
          //   sortable: false,
          // },
          // {
          //   name: 'priceCost',
          //   field: 'priceCost',
          //   label: '소계',
          //   type: 'cost',
          //   style: 'width: 150px',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '320px',
      },
      grid2: {
        columns: [],
        data: [],
        height: '300px',
      },
      getUrl: '',
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.$_.cloneDeep(this.param)
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    click() {
      this.getWorks();
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.getUrl = selectConfig.wod.workorder.plan.work.allsubs.url;

      this.getColumn();
      this.getWorks();
    },
    getWorks() {
      if (this.param.workPlanId) {
        this.$http.url = this.getUrl;
        this.$http.param = {
          workPlanId: this.param.workPlanId,
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data.materialList;
          this.grid2.data = _result.data.serviceList;
        },);
      }
    },
    getColumn() {
      // this.$comm.getComboItems('WO_COST_FACOTR_CD').then(_result => {
        this.grid2.columns = [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '항목번호',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'workPlanWorkName',
            field: 'workPlanWorkName',
            label: '작업명',
            align: 'left',
            sortable: false,
          },
          // {
          //   name: 'workOprTypeName',
          //   field: 'workOprTypeName',
          //   label: '작업제어구분',
          //   align: 'center',
          //   style: 'width:120px',
          //   sortable: false,
          // },
          // {
          //   name: 'workDtsStr',
          //   field: 'workDtsStr',
          //   label: '작업기간',
          //   align: 'center',
          //   style: 'width:180px',
          //   sortable: false,
          // },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'customDetail',
            field: 'customDetail',
            label: '',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false
          },
          // {
          //   name: 'workDt',
          //   field: 'workDt',
          //   label: '작업일',
          //   align: 'center',
          //   style: 'width: 140px',
          //   sortable: false,
          // },
          // {
          //   name: 'costFactorCd',
          //   field: 'costFactorCd',
          //   label: '원가요소',
          //   align: 'center',
          //   sortable: false,
          //   style: 'width: 200px',
          //   type: 'select',
          //   itemText: 'codeName',
          //   itemValue: 'code',
          //   comboItems: _result,
          // },
          // {
          //   name: 'manCnt',
          //   field: 'manCnt',
          //   label: '작업인원',
          //   align: 'right',
          //   style: 'width: 80px',
          //   sortable: false,
          // },
          // {
          //   name: 'priceCost',
          //   field: 'priceCost',
          //   label: '인력단가',
          //   type: 'cost',
          //   style: 'width: 120px',
          //   sortable: false,
          // },
          // {
          //   name: 'manCost',
          //   field: 'manCost',
          //   label: '인건비',
          //   type: 'cost',
          //   style: 'width: 120px',
          //   sortable: false,
          // },
          // {
          //   name: 'materialCost',
          //   field: 'materialCost',
          //   label: '자재비',
          //   type: 'cost',
          //   style: 'width: 120px',
          //   sortable: false,
          // },
          // {
          //   name: 'sumCost',
          //   field: 'sumCost',
          //   label: '합계',
          //   type: 'cost',
          //   style: 'width: 120px',
          //   sortable: false,
          // },
        ]
      // });
    },
    rowClickDetail(_row, _idx) {
      this.dialogDetail = true;
      this.selectedRow = _row;
      this.selectedRowIdx = _idx;
    },
    dialogClose() {
      this.dialogDetail = false;
    },
    rowClickDetail2(_row, _idx) {
      this.dialogDetail2 = true;
      this.selectedRow2 = _row;
      this.selectedRowIdx2 = _idx;
    },
    dialogClose2() {
      this.dialogDetail2 = false;
    },
  }
};
</script>